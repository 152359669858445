h1.houre-text {
    font-size: 15vw;
}

button.start-btn {
    background-color: #c1e8ff;
    border: 3px solid #76abca;
    border-radius: 18px;
    width: 15vw;
    height: 5vw;
    font-size: 3vw;
    font-weight: 600;
    /* box-shadow: 0px 2px 8px 0px #6769bb; */
    color: #5a9dc4;
    margin: 0px 5px;
}

button.pause-btn {
    background-color: #c1e8ff;
    border: 3px solid #76abca;
    border-radius: 18px;
    width: 15vw;
    height: 5vw;
    font-size: 3vw;
    font-weight: 600;
    /* box-shadow: 0px 2px 8px 0px #6769bb; */
    color: #5a9dc4;
    margin: 0px 5px;
}

button.resume-btn {
    background-color: #c1e8ff;
    border: 3px solid #76abca;
    border-radius: 18px;
    width: 15vw;
    height: 5vw;
    font-size: 3vw;
    font-weight: 600;
    /* box-shadow: 0px 2px 8px 0px #6769bb; */
    color: #5a9dc4;
    margin: 0px 5px;
}

button.restart-btn {
    background-color: #c1e8ff;
    border: 3px solid #76abca;
    border-radius: 18px;
    width: 15vw;
    height: 5vw;
    font-size: 3vw;
    font-weight: 600;
    /* box-shadow: 0px 2px 8px 0px #6769bb; */
    color: #5a9dc4;
    margin: 0px 5px;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

button.start-btn::after {
    background-color: #5b9dc3;
    border: 3px solid #c1e8ff;
    border-radius: 18px;
    width: 15vw;
    height: 5vw;
    font-size: 3vw;
    font-weight: 600;
    box-shadow: 0px 2px 8px 0px #5c9dc2;
    color: #c1e8ff;
    margin: 0px 5px;
}

button.start-btn:active {
    background-color: #5b9dc3;
    border: 3px solid #c1e8ff;
    border-radius: 18px;
    width: 15vw;
    height: 5vw;
    font-size: 3vw;
    font-weight: 600;
    box-shadow: 0px 2px 8px 0px #5c9dc2;
    color: #c1e8ff;
    margin: 0px 5px;
}

button.start-btn:hover {
    background-color: #5b9dc3;
    border: 3px solid #c1e8ff;
    border-radius: 18px;
    width: 15vw;
    height: 5vw;
    font-size: 3vw;
    font-weight: 600;
    box-shadow: 0px 2px 8px 0px #5c9dc2;
    color: #c1e8ff;
    margin: 0px 5px;
}
